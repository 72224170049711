<template>
  <div>
    <div class="hidden lg:block md:hidden">
      <div class="mx-80">
      <div class="flex flex-row py-10 pt-11 pl-11" style="background-color: #FFFFFF">
        <div class="w-1/2">
          <div class="flex flex-col">
            <div class="font-bold text text-5xl bg-[#]" style="color: #ffa140">
              Deploy & run transactions on the alphachain network using online an ide
            </div>

            <div class="pt-6">
              Alphachain Online IDE allows developing, deploying and administering smart contracts on the alphachain
              network
            </div>

            <div class="pt-6">
              <button class="
              bg-amber-400
              px-5
              py-2
              rounded-md
              drop-shadow-md
              text-black
              mt-3
            " @click="routeTo('https://remix.ethereum.org')">
                Online IDE
              </button>

            </div>

          </div>
        </div>

        <div>
          <img src="../assets/image1.svg" alt="" srcset="">
        </div>

      </div>

      <div class="flex flex-row py-10 pl-11 bg-[#FFFFFF]">
        <div>
          <div>
            <img src="../assets/image2.svg" alt="" srcset="">
          </div>
        </div>

        <div class="w-1/2 flex flex-col mt-auto mb-auto	pl-32 pb-14">
          <div class="font-bold text text-5xl" style="color: #ffa140">
            Start learning Solidity
          </div>

          <div class="pt-6">
            Solidity is an object oriented used to develop smart contracts on the Alphachain network and other
            Ethereum based blockchain network

          </div>

          <div class="pt-6">
            <button class="
              bg-amber-400
              px-5
              py-2
              rounded-md
              drop-shadow-md
              text-black
              mt-3
            " @click="routeTo('https://docs.soliditylang.org/en/v0.8.16/')">
              Documentation
            </button>
          </div>
        </div>
      </div>

        </div>
      <footer-view />
    </div>

    <div class="lg:hidden sm:block md:block">
      <div class=" py-10 pt-11 pl-11" style="background-color: #FFFFFF">
        <div class="w-full">
          <div class="flex flex-col">
            <div class="font-bold text text-5xl" style="color: #ffa140">
              Deploy & run transactions on the alphachain network using online an ide
            </div>

            <div class="pt-6">
              Alphachain Online IDE allows developing, deploying and administering smart contracts on the alphachain
              network
            </div>

            <div class="pt-6">
              <button class="
              bg-amber-400
              px-5
              py-2
              rounded-md
              drop-shadow-md
              text-black
              mt-3
            " @click="routeTo('https://remix.ethereum.org')">
                Online IDE
              </button>

            </div>

          </div>
        </div>

        <div class="block my-12">
          <img src="../assets/image1.svg" alt="" srcset="">
        </div>

      </div>

      <div class="py-10 pl-11" style="background-color: #F5F7F8">
        <div>
          <div>
            <img src="../assets/image2.svg" alt="" srcset="">
          </div>
        </div>

        <div class="w-full my-12 pb-14">
          <div class="font-bold text text-5xl" style="color: #ffa140">
            Start learning Solidity
          </div>

          <div class="pt-6">
            Solidity is an object oriented used to develop smart contracts on the Alphachain network and other
            Ethereum based blockchain network

          </div>

          <div class="pt-6">
            <button class="
              bg-amber-400
              px-5
              py-2
              rounded-md
              drop-shadow-md
              text-black
              mt-3
            " @click="routeTo('https://docs.soliditylang.org/en/v0.8.16/')">
              Documentation
            </button>
          </div>
        </div>
      </div>
      <footer-view />
    </div>

  </div>
</template>

<script>
import FooterView from "../components/FooterView.vue";
import CardView from "../components/CardView.vue";
export default {
  name: "Developer",
  components: {
    FooterView,
    CardView,
  },
  data() {
    return dataStore
  },
  mounted() {
    dataStore.activeTab = 'Developer'
  },
  methods: {
    routeTo(dest) {
      window.open(dest, '_blank');
    }
  }
};
</script>
