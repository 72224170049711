
import { dataStore } from "@/states/states";
export default {
  name: "MoreLeft",

  data() {
    return dataStore;
  },
  methods: {
    changeConsensus() {
      dataStore.moreCurrent = "consensus";
      dataStore.title = "Consensus Mechanism";
      dataStore.desc =
        "The Alphachain network uses Proof of Work (PoW) mechanism ";
    },
    changeWhy() {
      dataStore.moreCurrent = "why";
      dataStore.title = "Why alphachain network";
      dataStore.desc =
        "The Alphachain network aims to connect its users to multiple blockchain networks allowing an easy and secure follow of blockchain based crypto-assets.";
    },
  },
};
