
// import { dataStore } from '../states/states.ts'
import { dataStore } from '../states/states'
import { addNetwork } from './walletConnection'

export default {
  name: "FooterView",
  data() {
    return dataStore
  },
  methods: {
    routeTo(dest: string) {
      window.open(dest,'_blank');
    },

    // async addNetwork(type: string) {



    //         // if (type === 'web3') {
    //         //     if (typeof ethereum !== 'undefined') {
    //         //         eth = new Web3Eth(ethereum);
    //         //     } else if (typeof web3 !== 'undefined') {
    //         //         eth = new Web3Eth(web3.givenProvider);
    //         //     } else {
    //         //         eth = new Web3Eth(ethereum);
    //         //     }
    //         // }

             

    //         // let params = [{
    //         //                 chainId: '12b3d81',
    //         //                 chainName: 'Alphachain Live',
    //         //                 nativeCurrency: {
    //         //                     name: 'ALP',
    //         //                     symbol: 'ALP',
    //         //                     decimals: 18
    //         //                 },
    //         //                 rpcUrls: ['https://rpc.all.co.tz'],
    //         //                 blockExplorerUrls: ['https://explorer.all.co.tz']
    //         //             }]

    //         //             let w = window.web3

                
    //         //     window.ethereum.request({ method: 'wallet_addEthereumChain', params })
    //         //         .then(() => console.log('Success'))
    //         //         .catch((error: any) => console.log("Error", error.message));


    //     }
    async addNetwork1() {
      addNetwork()      
}
  }
};
