
import FooterView from "../components/FooterView.vue";
import dapp from "../components/dapp.vue";
import CardView from "../components/CardView.vue";
import { dataStore } from "../states/states";

export default {
  name: "dapps",
  components: {
    FooterView,
    dapp,
    CardView,
  },
  data() {
    return dataStore;
  },
  mounted() {
    dataStore.activeTab = "dapps";
  },
};
