<template>
  <div
    class="
      text-center
      border
      p-4
      rounded-lg
      hover:rounded-none hover:bg-slate-50
      hover:drop-shadow-md
      lg:w-1/3
      md:w-1/3
    "
  >
    <h1 class="font-bold text-4xl text-start">{{title}}</h1>
    <h2 class="text-start py-4">
      {{desc}}
    </h2>
    <div class="flex">
      <button
        class="
          bg-amber-400
          py-2
          px-6
          rounded-lg
          text-start
          drop-shadow-md
          hover:bg-amber-500
        "
        @click="navigate()"
      >
        Visit
      </button>
    </div>
  </div>
</template>

<script>
export default {
    name: 'dapp',
    props: {
      title: {
        default: 'Default',
        type: String
      },
      desc: {
        default: 'dapp',
        type: String
      },
      link: {
        default: 'https://explorerv2.all.co.tz',
        type: String
      }
    },
    methods: {
      navigate() {
        alert('This dApp is coming soon')
        // window.open(this.link)
      }
    }
};
</script>

<style>
</style>