<template>
   <div>
<div class="hidden lg:block md:hidden">
  <div class="items-center bg-[#f5f7f8]" >
     <div class="mx-80">
      <div class="flex flex-row py-10 pl-11">
        <div class="w-1/2">
          <div id="carouselExampleControls" class="carousel slide relative" data-bs-ride="carousel">
            <div class="carousel-inner relative w-full overflow-hidden">
              <div class="carousel-item active relative float-left w-full">
                <img
                  src="../assets/Group63.svg"
                  class="block w-full"
                  alt="Wild Landscape"
                />
              </div>
              <div class="carousel-item relative float-left w-full">
                <img
                  src="../assets/Group45.svg"
                  class="block w-full"
                  alt="Camera"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
            <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
              <span class="visually-hidden m-2">Next</span>
            </button>
          </div>
        </div>
        <div class="w-1/2 flex flex-col mt-auto mb-auto	pl-32 pb-14">
          <div class="font-bold text text-5xl text-[#104050]">
            <span class="">
              Alphachain Wallet
            </span>
          </div>

          <div class="pt-6 flex flex-col space-y-8 ">
            <span class=" text-[#104050] text-justify">
              A secure and user-friendly solution for managing your blockchain based digital assets in the web3 ecosystem. With advanced encryption technology, your assets are protected accessible only by you. 
            </span>

            <span class="text-[#104050] space-y-4 text-justify">
              Join the decentralized revolution with Alphachain Wallet, your trusted partner in the world of digital assets.
            </span>

            <span class="text-[#104050]">
              Download the alphachain wallet browser extensions  for your browser
            </span>
          </div>
          <div class="pt-6">
            <div class="columns-3 mt-6 py-1">
        <div style="background-color: #f5f7f8" class="p-6 rounded-lg hover:cursor-pointer" @click="downloadWallet('chrome')">
          <div class="flex flex-col items-center">
            <Icon icon="logos:chrome" class="text-3xl"/>
            <span class="font-bold px-4" style="color:#104050">Chrome</span>
          </div>
        </div>
        <div style="background-color: #f5f7f8" class="p-6 rounded-lg hover:cursor-pointer" @click="downloadWallet('firefox')">
            <div class="flex flex-col  items-center"> 
            <Icon icon="logos:firefox" class="text-3xl"/>
            <span class="font-bold px-4" style="color:#104050">Firefox</span>
            </div>
        </div>
        <div style="background-color: #f5f7f8" class="p-6 rounded-lg hover:cursor-pointer" @click="downloadWallet('brave')">
            <div class="flex flex-col items-center">
                <Icon icon="logos:brave" class="text-3xl" />
                <span class="font-bold px-4" style="color:#104050">Brave</span>
            </div>  
        </div>
      </div>
          </div>
        </div>
      </div>
     </div>
   </div>
    <div class="mx-80">
      <div class="flex flex-row py-10  pl-11 bg-[#FFFFFF]">
        <div class="w-1/2">
          <div class="flex flex-col">
            <div class="text-[#104050] font-bold text-3xl py-4">
              What is a Web 3 Wallet ?
            </div>
            <div class="flex flex-col py-4">
              <div class="p-8 rounded-3xl bg-[#104050]">
               <span class="text-white justify-auto">
                A software program wallet that is a crucial component of the web3 ecosystem, 
                serving as a secure digital storage solution for managing and facilitating users interactions with the a blockchain network . 
               </span>
            </div>
             <div>
                <span class="p-4">

                </span>
             </div>
            <div class="p-8 rounded-3xl bg-[#104050]">
              <span class="text-white justify-auto">
                It provides an encrypted virtual space for holding and accessing blockchain based products such as Nfts, Defi, Dapps, cryptocurrencies .
                 The wallet securely stores private keys and public addresses, allowing users to access and manage their digital assets on web 3. 
               </span>
            </div>
            </div>

          </div>
        </div>
        <div class="pl-10">
          <img src="../assets/qn.svg" alt="" srcset="">
        </div>
      </div>
    </div>

    <div class="mx-80">
      <div class="bg-[#f5f7f8]">

      </div>
       <div class="flex flex-col">
            <div class="flex place-items-center justify-center">
               <span class="text-3xl text-[#104050] font-bold py-4">
                How to install the wallet
               </span>
            </div>
              
            <div class="flex flex-col">
              <div class="flex flex-cols-3 space-x-8 py-4">
                 <div>
                  <div class="flex flex-col bg-[#104050] rounded-3xl text-white p-6">
                     <span class="font-bold justify-auto">
                       Step 1
                     </span>
                      <span>
                        click the chrome browser extension to download
                    </span>
                </div>
                <div class="p-8">
                  <div class="">
                   <img src="../assets/dwnchrome1.svg">
                 </div>
                </div>
                 </div>
                 <div>
                  <div class="flex flex-col bg-[#104050] rounded-3xl text-white p-6">
                     <span class="font-bold justify-auto">
                       Step 2
                     </span>
                      <span>
                        Unzip the downloaded web browser extension
                    </span>
                </div>
                <div class="p-8">
                  <div class="">
                   <img src="../assets/unzip1.svg">
                 </div>
                </div>
                </div>
                <div>
                  <div class="flex flex-col bg-[#104050] rounded-3xl text-white p-6">
                     <span class="font-bold justify-auto">
                       Step 3
                     </span>
                      <span>
                        Select More tools then Extension
                    </span>
                </div>
                 <div class="p-8">
                  <div class="">
                   <img src="../assets/extensions1.svg">
                 </div>
                 </div>
                </div>
              </div>
               <div class="flex flex-cols-3 space-x-8">
                 <div>
                  <div class="flex flex-col bg-[#104050] rounded-3xl text-white p-6">
                    <span class="font-bold justify-auto">
                       Step 4
                    </span>
                    <span>
                      Turn the developer mode on then Load unpacked 
                    </span>
                </div>
                   <div class="p-8">
                     <div class="">
                       <img src="../assets/devmode1.svg">
                     </div>
                      <div>
                        <img src="../assets/devmode2.svg">
                      </div>
                 </div>
                 </div>
                   <div>
                    <div class="flex flex-col bg-[#104050] rounded-3xl text-white p-6">
                       <span class="font-bold justify-auto">
                         Step 5
                       </span>
                        <span>
                           Upon seeing this, the wallet has successful been install
                        </span>
                        <span>
                          Please follow the steps on how to get started
                        </span>
                        <span>
                          Visit the <a href="https://faucet.all.co.tz/" class="text-white hover:text-[#FFA140]">faucet</a> for some Alp coin to get started 
                        </span>
                    </div>
                    <div class="p-8">
                        <img src="../assets/wallet1.svg">
                    </div>
                  <div>
                    <div class="flex flex-col bg-[#ffff] rounded-3xl text-white p-6 cursor-default">
                       <span>
                       </span>
                        <span class="p-6 cursor-default">
                          ............................................................
                        </span>
                    </div>
                  </div>
                   </div>
              </div>
            </div>
       </div>
    </div>

    <div class="md:mt-10">
      <FooterView />
    </div>
   </div>
<div class="lg:hidden sm:block md:block">
  <div class="bg-[#f5f7f8] w-full" >
     <div class="">
      <div class="flex-col">
        <div class="">
          <div id="carouselExampleControls" class="carousel slide relative" data-bs-ride="carousel">
            <div class="carousel-inner relative w-full overflow-hidden py-4">
              <div class="carousel-item active relative float-left w-full">
                <img
                  src="../assets/Group63.svg"
                  class="block w-full"
                  alt="Wild Landscape"
                />
              </div>
              <div class="carousel-item relative float-left w-full">
                <img
                  src="../assets/Group45.svg"
                  class="block w-full"
                  alt="Camera"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
            <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
              <span class="visually-hidden m-2">Next</span>
            </button>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="font-bold text text-3xl text-[#104050] items-center">
            <span class="flex items-center justify-center">
              Alphachain Wallet
            </span>
          </div>

          <div class="p-8 flex flex-col space-y-4 ">
            <span class=" text-[#104050] text-justify">
              A secure and user-friendly solution for managing your blockchain based digital assets in the web3 ecosystem. With advanced encryption technology, your assets are protected accessible only by you. 
            </span>

            <span class="text-[#104050] space-y-2 text-justify">
              Join the decentralized revolution with Alphachain Wallet, your trusted partner in the world of digital assets.
            </span>

            <span class="text-[#104050]">
              Download the alphachain wallet browser extensions  for your browser
            </span>
          </div>
          <div class="pt-6">
        <div class="columns-3 mt-6 py-1">
        <div style="background-color: #f5f7f8" class="p-6 rounded-lg hover:cursor-pointer" @click="downloadWallet('chrome')">
          <div class="flex flex-col items-center">
            <Icon icon="logos:chrome" class="text-3xl"/>
            <span class="font-bold px-4" style="color:#104050">Chrome</span>
          </div>
        </div>
        <div style="background-color: #f5f7f8" class="p-6 rounded-lg hover:cursor-pointer" @click="downloadWallet('firefox')">
            <div class="flex flex-col  items-center"> 
            <Icon icon="logos:firefox" class="text-3xl"/>
            <span class="font-bold px-4" style="color:#104050">Firefox</span>
            </div>
        </div>
        <div style="background-color: #f5f7f8" class="p-6 rounded-lg hover:cursor-pointer" @click="downloadWallet('brave')">
            <div class="flex flex-col items-center">
                <Icon icon="logos:brave" class="text-3xl" />
                <span class="font-bold px-4" style="color:#104050">Brave</span>
            </div>  
        </div>
      </div>
          </div>
        </div>
      </div>
     </div>
   </div>
    <div class="">
      <div class="flex flex-col py-10  bg-[#FFFFFF]">
        <div class="w-full">
          <div class="flex flex-col p-8">
            <div class="text-[#104050] font-bold text-xl py-4">
              What is a Web 3 Wallet ?
            </div>
            <div class="flex flex-col">
              <div class="p-8 rounded-3xl bg-[#104050]">
               <span class="text-white justify-auto">
                A software program wallet that is a crucial component of the web3 ecosystem, 
                serving as a secure digital storage solution for managing and facilitating users interactions with the a blockchain network . 
               </span>
            </div>
             <div>
                <span class="p-4">

                </span>
             </div>
            <div class="p-8 rounded-3xl bg-[#104050]">
              <span class="text-white justify-auto">
                It provides an encrypted virtual space for holding and accessing blockchain based products such as Nfts, Defi, Dapps, cryptocurrencies .
                 The wallet securely stores private keys and public addresses, allowing users to access and manage their digital assets on web 3. 
               </span>
            </div>
            </div>

          </div>
        </div>
        <div class="p-8 ">
          <img src="../assets/qn.svg" alt="" srcset="">
        </div>
      </div>
    </div>

    <div class="p-8">
      <div class="bg-[#f5f7f8]">

      </div>
       <div class="">
            <div class="flex place-items-center justify-center">
               <span class="text-3xl text-[#104050] font-bold">
                How to install the wallet
               </span>
            </div>
              
            <div class="">
              <div class="space-y-8">
                 <div>
                  <div class="flex flex-col bg-[#104050] rounded-3xl text-white p-6">
                     <span class="font-bold justify-auto">
                       Step 1
                     </span>
                      <span>
                        click the chrome browser extension to download
                    </span>
                </div>
                <div class="p-8">
                  <div class="">
                   <img src="../assets/dwnchrome1.svg">
                 </div>
                </div>
                 </div>
                 <div>
                  <div class="flex flex-col bg-[#104050] rounded-3xl text-white p-6">
                     <span class="font-bold justify-auto">
                       Step 2
                     </span>
                      <span>
                        Unzip the downloaded web browser extension
                    </span>
                </div>
                <div class="p-8">
                  <div class="">
                   <img src="../assets/unzip1.svg">
                 </div>
                </div>
                </div>
                <div>
                  <div class="flex flex-col bg-[#104050] rounded-3xl text-white p-6">
                     <span class="font-bold justify-auto">
                       Step 3
                     </span>
                      <span>
                        Select More tools then Extension
                    </span>
                </div>
                 <div class="p-8">
                  <div class="">
                   <img src="../assets/extensions1.svg">
                 </div>
                 </div>
                </div>
              </div>
               <div class="space-y-8">
                 <div>
                  <div class="flex flex-col bg-[#104050] rounded-3xl text-white p-6">
                    <span class="font-bold justify-auto">
                       Step 4
                    </span>
                    <span>
                      Turn the developer mode on then Load unpacked 
                    </span>
                </div>
                   <div class="p-8">
                     <div class="">
                       <img src="../assets/devmode1.svg">
                     </div>
                      <div>
                        <img src="../assets/devmode2.svg">
                      </div>
                 </div>
                 </div>
                   <div>
                    <div class="flex flex-col bg-[#104050] rounded-3xl text-white p-6">
                       <span class="font-bold justify-auto">
                         Step 5
                       </span>
                        <span>
                           Upon seeing this, the wallet has successful been install
                        </span>
                        <span>
                          Please follow the steps on how to get started
                        </span>
                        <span>
                          Visit the <a href="https://faucet.all.co.tz/" class="text-white hover:text-[#FFA140]">faucet</a> for some Alp coin to get started 
                        </span>
                    </div>
                    <div class="p-8">
                        <img src="../assets/wallet1.svg">
                    </div>
                  <div>
                    <div class="flex flex-col bg-[#ffff] rounded-3xl text-white p-6 cursor-default">
                       <span>
                       </span>
                        <span class="p-6 cursor-default">
                          ............................................................
                        </span>
                    </div>
                  </div>
                   </div>
              </div>
            </div>
       </div>
    </div>

    <div class="">
      <FooterView />
    </div>
   </div>
   </div>
</template>


<script >
import FooterView from '../components/FooterView.vue'
import { Icon } from "@iconify/vue2";
import { dataStore } from "../states/states";
import 'tw-elements';


export default {
  name: "Wallet",
  components: {
    FooterView,
    Icon,
  },
  data() {
    return dataStore
  },
  methods: {
    downloadWallet(platform) {
      if (platform !== 'android' && platform !== 'ios') 
      window.open(`/wallets/alphawallet-${platform}-1.1.0.zip`)
      else window.alert(`${platform} wallet coming soon!`)
    }
  },
  mounted() {
    dataStore.activeTab = 'wallet'
  }
};

</script>

<style>
#lay2{
      width: 0%;
      z-index: 8;
    }
</style>