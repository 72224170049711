export const addNetwork = () => {

    let params = [{
        chainId: '0x12b3d81',
        chainName: 'Alphachain Live',
        nativeCurrency: {
            name: 'ALP',
            symbol: 'ALP',
            decimals: 18
        },
        rpcUrls: ['https://rpc.all.tz'],
        blockExplorerUrls: ['https://explorer.all.co.tz']
    }]


    window.ethereum.request({ method: 'wallet_addEthereumChain', params })
    .then(() => console.log('Success'))
    .catch((error) => console.log("Error", error.message));
    
}