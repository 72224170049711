<template>
  <div>
    <button>
    {{title}}
    ss
  </button>
  </div>
</template>

<script>
export default {
    name: 'BtnComponent',
    props: {
        title: {
            default: 'Click',
            type: String
        }
    }
}
</script>

<style>

</style>