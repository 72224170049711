<template>
  <div>
    <div class="flex items-center p-10" style="background-color: #F2F2F2;" id="">
      <div class="w-1/2"  id="">
        <h1 class="text-5xl mb-10 font-bold text-[#FBBF24]">Join our community</h1>
        <p class=" text-xl text-[#104050]" >
          Joining our  developer community, have access to a supportive and welcoming network of like-minded individuals 
          who are passionate about blockchain technology and its potential to shape the future 
        </p>
        <div class="custombutton" id="custombutton">
          <button class="text-2xl font-bold text-[#104050] text-center"  
          @click="routeTo('https://oxygen.co.tz/#/room/#dev:app.oxygen.co.tz')">
            Join Us
          </button>
        </div>
      </div> 
      <div class="w-1/2">
        <img class="w-50 h-50" src="../assets/community.jpg" alt="BG_IMG" id="bg-img"/>
      </div>
    </div>
  </div>
</template>

<script>
import BtnComponent from "./BtnComponent.vue";

export default {
  components: { BtnComponent },
  name: "Community",
  components: {
    BtnComponent,
  },
  methods: {
    routeTo(dest) {
      window.open(dest,'_blank');
    }
  }
};
</script>

<style lang="scss">
   #container {
    height: calc(100vh - 40vh);    
    #overlay {
      background-color: #F1F5F9;
      height: calc(100vh - 40vh);
      position: absolute;
      width: 100%;
      opacity: 0.8;
      z-index: 3;
    }
    #bg-img {
      position: relative;
      width: 100%;
      height: calc(100vh - 40vh);
    }
    #headings {
  position: absolute;
  z-index: 5;
  margin-top: 8%;
  width: 30%;
  left: 50%;
  margin-left: -15%; /* Adjusted margin for better centering */
  padding: 15px;
  border: 1px solid transparent; /* Transparent border */
  box-shadow: 0 0 10px rgba(255, 161, 64, 0.5); /* Blur effect */
  border-radius: 20px;
  animation: border-animation 10s ease-in-out infinite; /* Border animation */
}

@keyframes border-animation {
  0% {
    box-shadow: 0 0 10px rgba(255, 161, 64, 0.5);
  }
  100% {
    box-shadow: 0 0 10px #FFA140; /* Animated border color */
  }
}
}


#custombutton {
  margin-top: 8%;
  width: 15%;
  padding: 10px;
  background-color: #FBBF24;
  color: white;
  border: none;
  border-radius: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add box-shadow to transition */
  box-shadow: 0 0 0 transparent; /* Initial shadow (no blur) */
}

#custombutton:hover {
  transform: translateX(5%) scale(1.1); /* Scale up on hover */
  box-shadow: 0 0 100px rgba(255, 161, 64, 0.5); /* Blur effect on hover */
  background-color: #FBBF24; /* Maintain original color on hover */
}


</style>