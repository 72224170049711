import { reactive } from 'vue'

export var dataStore = reactive({
    title: 'Why alphachain network',
    desc: 'The Alphachain network aims to connect its users to multiple blockchain networks allowing an easy and secure follow of blockchain based crypto-assets.',
    moreCurrent: 'why',

    blocks: '_',
    gLimit: '_',
    currentDiff: '_',

    activeTab: 'home'
})