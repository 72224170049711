

import FooterView from '../components/FooterView.vue'

export default {
    name: 'ComingSoon',
    components: {
        FooterView
    }
}
