<template>
  <div
    class="
    justify-between
      text-center
      border
      p-4
      rounded-lg
      hover:rounded-none hover:bg-slate-50
      hover:drop-shadow-md
      lg:w-1/3
      md:w-1/3
    "
  >
    <div>
      <h1 class="font-bold text-4xl text-start">{{title}}</h1>
      <h2 class="text-start py-4">
        {{desc}}
      </h2>
    </div>
    <div class="flex">
      <button
        class="
          bg-amber-400
          py-2
          px-6
          rounded-lg
          text-start
          drop-shadow-md
          hover:bg-amber-500
        "
        @click="navigate()"
      >
        Visit
      </button>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CardView',
    props: {
      title: {
        default: 'Default',
        type: String
      },
      desc: {
        default: 'Description',
        type: String
      },
      link: {
        default: 'https://explorerv2.all.co.tz',
        type: String
      }
    },
    methods: {
      navigate() {
        if(this.link.split('')[0] != '/') window.open(this.link)
        else this.$router.push(this.link)
      }
    }
};
</script>

<style>
</style>