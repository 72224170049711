import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import ComingSoon from '../views/ComingSoon.vue'
import Wallet from '../views/Wallets.vue'
import dapps from '../views/dapps.vue'
import Developer from '../views/Developer.vue'
import tools from "@/components/tools.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/coming-soon',
    name: 'home',
    component: ComingSoon
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet
  },
  {
    path: '/Developer',
    name: 'Developer',
    component: Developer
  },
  {
    path: '/dapps',
    name: 'dapps',
    component: dapps
  },
  {
    path: '/tools',
    name: 'tools',
    component: tools
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
