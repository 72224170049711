
import More from "@/components/More.vue";
import CardView from "../components/CardView.vue";
import Community from "../components/Community.vue";
import FooterView from "@/components/FooterView.vue";
import { dataStore } from '../states/states'
import { Icon } from "@iconify/vue2";

export default {
  name: "Home",
  components: {
    CardView,
    Community,
    More,
    FooterView,
    Icon,
  },
  data() {
    return dataStore
  },
  mounted() {
    dataStore.activeTab = 'home'
  }
};

const textLines: string[] = [
  "Karibu Alphachain Network",
  "Blockchain Developer’s First choice"
];

const typingSpeed: number = 100; // Adjust the typing speed (in milliseconds)
const delayBetweenLines: number = 1000; // Delay between lines (in milliseconds)

const line1Element: HTMLElement | null = document.getElementById('line1');
const line2Element: HTMLElement | null = document.getElementById('line2');

function typeWords(element: HTMLElement, words: string[], index: number): void {
  if (index < words.length) {
    element.innerHTML += words[index] + ' ';
    setTimeout(() => {
      typeWords(element, words, index + 1);
    }, typingSpeed);
  }
}

function typeLines(): void {
  if (line1Element && line2Element) {
    const wordsLine1: string[] = textLines[0].split(' ');
    const wordsLine2: string[] = textLines[1].split(' ');

    typeWords(line1Element, wordsLine1, 0);

    setTimeout(() => {
      typeWords(line2Element, wordsLine2, 0);
    }, wordsLine1.length * typingSpeed + delayBetweenLines);
  }
}

// Start typing animation
typeLines();


